.tenantSelectionMenuButton:hover {
  cursor: pointer;
  background: #e0f5fd 0% 0% no-repeat padding-box;
  border-radius: 5px;
}
.tenantSelectionMenuButton:hover > i {
  color: #00aeef;
}

.tenantSelectionMenuButton > i {
  font-size: 30px;
  line-height: 36px;
  color: #9ca1a6;
  margin-left: 8px;
}
