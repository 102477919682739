.dots {
  background: #157d28;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  margin-right: 5px;
}

.dotFaults {
  background: #dd1f16;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  margin-right: 5px;
}
.dots3 {
  left: 15px;
  border: 2px solid #ecedee;
}

.dotWarnings {
  background: #f69602;
  border-radius: 50%;
  height: 14px;
  margin-right: 5px;
  width: 14px;
}
.nonActiveEquipmentStatusView:after {
  content: "";
  display: block;
  width: 9px;
  height: 9px;
  background-color: #fff;
  transform: translateY(-30%) rotate(45deg);
  position: absolute;
  left: 15px;
  border-right: 1px solid #e0e1e3;
  border-bottom: 1px solid #e0e1e3;
  bottom: -7px;
  margin-left: -15px;
}
.nonActiveEquipmentStatusView:hover {
  box-shadow: 0px 3px 6px #00000029;
}
