.sectionOne {
  display: flex;
  justify-content: column;
  margin: 3em;
}
.notificationTable {
  width: 70%;
}

@media only screen and (max-width: 600px) {
  .sectionOne {
    display: block;
    margin: 1em;
  }
  .notificationTable {
    width: 100%;
  }
}
