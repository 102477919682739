.menuItem {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  align-items: center;
}
/* 
.menuItem:hover {
  color: #17455e;
} */
