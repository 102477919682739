.custom-tooltip::before {
  content: " ";
  height: 0;
  position: absolute;
  width: 0;
  left: -18px;
  top: 10px;
  border: 10px solid transparent;
  border-right-color: #fff;
}
