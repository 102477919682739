.columnHeader {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #9ca1a6;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  vertical-align: middle;
  cursor: pointer;
  position: sticky;
  top: 0px;
  z-index: 100;
  background-color: #ffffff;
}

.MuiBox-root.css-1isemmb {
  min-width: 52px;
}
