.MuiAlert-message {
  width: 432px;
  font: normal normal normal 12px/16px Roboto;
}
.MuiAlert-icon {
  display: flex;
  align-items: center;
}

.MuiAlert-root {
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 4px !important;
  display: flex;
  align-items: center;
}

.MuiAlertTitle-root {
  font: normal normal medium 14px/16px Roboto !important;
  padding: 0 !important;
  margin: 0 !important;
}

.undoButton {
  background-color: #34a852;
  width: 93px;
  height: 35px;
  color: #f7f8f8;
  font: normal normal medium 16px/19px Roboto;
}
.undoButton:hover {
  background-color: #34a852;
}
