html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-family: "Roboto";
}

body {
  line-height: 1;
  overflow-y: hidden;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

.muiToolBar-root {
  background-color: aqua;
}

.split-pane-col {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ecedee;
  overflow-y: hidden;
}

.split-pane-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #ecedee;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.split-pane-row::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.split-pane-top,
.split-pane-bottom,
.split-pane-right,
.split-pane-left {
  flex: 1;
  border-radius: 4px;
  background-color: #fff;
  align-items: stretch;
  width: 100%;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  max-width: 100%;
}

.split-pane-left::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.split-pane-left {
  max-width: auto !important;
}

.split-data {
  width: 50%;
}

.split-pane-right {
  border-radius: 4px 4px 4px 4px !important;
}

/*
.split-pane-right {
    max-width: 25%;
}
 background-image: linear-gradient(
        90deg,
        transparent 79px,
        #abced4 79px,
        #abced4 81px,
        transparent 81px
      ),
      linear-gradient(#eee 0.1em, transparent 0.1em);
    background-size: 100% 1.2em;

}
*/
.split-pane-top,
.split-pane-bottom {
  background-color: #fff;
  text-align: left;
  border-radius: 0px 0px 6px 6px;
}

.separator-col {
  border: 5px solid #ecedee;
  background-color: #ecedee;
  /* cursor: col-resize; */
  margin: 0;
  padding: 0;
  cursor: col-resize;
}

.separator-col:hover {
  background: #e0e1e3;
  border: 5px solid #e0e1e3;
}

.separator-col:active {
  background: #e0f5fd;
  border: 5px solid #e0f5fd;
}

.separator-col:focus {
  background: #e0f5fd;
  border: 5px solid #e0f5fd;
}

.center-element-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #9ca1a6;
  opacity: 1;
  padding: 0;
  height: 70%;
  margin: -2px;
}

.separator-row {
  /* border: 4px solid #ECEDEE; */
  background-color: #ecedee;
  cursor: row-resize;
}

.separator-row:hover {
  background: #e0e1e3;
}

.separator-row:active {
  background: #e0f5fd;
}

.separator-row:focus {
  background: #e0f5fd;
}

.center-element-row {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9ca1a6;
  opacity: 1;
  padding: 0;
  margin-top: -7px;
  margin-bottom: -7px;
}

.draggable {
  border-radius: 4px;
  background: #cc0000;
  width: 40px;
  height: 40px;
}

.hide {
  opacity: 0.1;
  transition: 0.01s;
  transform: translateX(-9999px);
}

.required::after {
  content: " *";
  color: red;
}

svg {
  fill: black;
}

.parent {
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: auto;
  margin: 0;
}

.child {
  flex-grow: 1;
  min-height: 0;
  overflow-x: auto;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 25% !important;
}

.css-11mde6h-MuiPaper-root {
  box-shadow: none !important;
  margin-top: -4px;
}

.css-1wku4a4 {
  padding: 0px 5px !important;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-1rhbuit-multiValue {
  background-color: #fff !important;
}

.tss-1ls58sa-MUIDataTableBodyCell-simpleCell {
  color: #222934;
}

.tss-131sses-MUIDataTable-responsiveScroll {
  max-height: 78vh !important;
  overflow: overlay !important;
}

.tss-10rusft-MUIDataTableToolbar-icon {
  width: 0px !important;
}

.tss-x3hmze-MUIDataTableToolbar-actions {
  align-items: center !important;
}

.css-clamcd-MuiButtonBase-root-MuiIconButton-root:hover,
.css-11fk796:hover {
  color: transparent !important;
  background-color: transparent !important;
}
.tss-10rusft-MUIDataTableToolbar-icon:hover {
  color: "transparent";
}

.css-vayibc-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border: 1px solid #9ca1a6 !important;
  border-radius: 4px !important;
}

.tss-1fz4yw6-MUIDataTableSelectCell-fixedLeft {
  padding-left: 16px !important;
  position: relative !important;
}

.error-message {
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #eb4436;
}

.valid-message {
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #34a852;
  opacity: 1;
}

.drawer-title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 500 !important;
}
.css-1u9des2-indicatorSeparator,
.css-894a34-indicatorSeparator {
  display: none;
}
.css-3iigni-container {
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border: 1px solid #c0c3c7;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #c0c3c7;
  opacity: 1;
}
.css-9o6rjw-MuiButtonBase-root-MuiIconButton-root:hover {
  color: inherit !important;
  background-color: inherit !important;
}

.css-1q1u3t4-MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: #ecedee !important;
}

.css-9o6rjw-MuiButtonBase-root-MuiIconButton-root,
.css-1ogufel {
  color: white !important;
  padding: 0 !important;
  font-size: 16px !important;
}

.tss-1qtl85h-MUIDataTableBodyCell-root {
  white-space: nowrap !important;
}
.css-13cymwt-control {
  min-height: 40px !important;
}

.css-ki1hdl-MuiAlert-action {
  padding: 0 !important;
  margin: 0 !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  border: 1px solid #9ca1a6 !important;
  border-radius: 4px;
}

.css-hyum1k-MuiToolbar-root,
.css-i6s8oy {
  padding: 0px 16px !important;
}

.menuItem:hover .checkBox {
  color: #17455e;
}

.MuiFormControl-root {
  width: 100% !important;
}

.tss-lqxvey-MUIDataTableSelectCell-expanded {
  transform: rotate(180deg) !important;
}

.PrivateSwipeArea-root {
  display: none !important;
}

.css-1jaw3da,
.css-j204z7-MuiFormControlLabel-root {
  margin: 0 !important;
}

/* Scroll Bar - Chrome & Safari */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #c0c3c7;
  border-radius: 30px;
  width: 5px;
  background-clip: content-box;
  border: 4px solid transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #b6b7b8;
}

::-webkit-scrollbar {
  width: 15px;
}

.css-ao8rxk {
  box-shadow: none !important;
}

.css-1853f2q-MuiInputBase-root-MuiOutlinedInput-root,
.css-dozlyc {
  padding: 10px 8px !important;
  display: flex !important;
  flex-direction: column !important;
}
.css-1yjjitx-MuiSwitch-track {
  opacity: 1 !important;
  background: #17455e 0% 0% no-repeat padding-box !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase:hover,
.css-1nr2wod.Mui-checked:hover,
.css-1nr2wod:hover .MuiSwitch-input:hover {
  color: white !important;
}

.css-14yr603-MuiStepContent-root,
.css-d0mviz {
  border-left: 2px solid #17455e !important;
  margin-left: 11px !important;
}
.css-14sza3e-MuiStepLabel-root,
.css-r12ba4 {
  padding: 0 !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active,
.css-4ff9q7.Mui-active {
  color: #17455e !important;
}

.css-8t49rw-MuiStepConnector-line,
.css-vgb7rt {
  border-left: 2px solid #17455e !important;
}

.css-1pe7n21-MuiStepConnector-root,
.css-52tf1x {
  margin-left: 11px !important;
}

.css-1rw7nzj-MuiCollapse-root-MuiStepContent-transition,
.css-inp9se {
  margin-top: -20px !important;
  margin-left: 28px !important;
}
.css-1nr2wod.Mui-checked + .MuiSwitch-track {
  opacity: 1 !important;
}
.css-6hp17o-MuiList-root-MuiMenu-list,
.css-r8u8y9 {
  margin-top: 0px !important;
}
.css-1jqq78o-placeholder {
  color: inherit !important;
}
.css-igs3ac,
.css-4t1jhe-control {
  border: none !important;
}

.tss-qw25q6-MUIDataTableSelectCell-fixedHeader {
  position: sticky !important;
}
table {
  border-collapse: separate !important;
}

.recharts-responsive-container {
  position: absolute !important;
}
.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root,
.css-18lqwt2 {
  color: #17455e !important;
  font-size: 16px !important;
  line-height: 21px !important;
  font-weight: 500 !important;
}

.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected,
.css-18lqwt2.Mui-selected {
  color: white !important;
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  background-color: #17455e !important;
}

.device-link {
  color: #00aeef;
  text-decoration: none;
}

.device-link:hover {
  text-decoration: underline;
}
