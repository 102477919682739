.InputFieldOperator {
  cursor: pointer;
  font-size: 16px;
  color: #9ca1a6;
  font-weight: lighter;
}

.addSensorButton {
  color: #17455e;
  font-size: 16px;
  line-height: 19px;
  border: none;
  background: transparent;
  font-weight: 500;
  padding: 0;
  cursor: pointer;
}

.addSensorButton:hover {
  color: #11364c;
}

.suppressDrawerHeader {
  display: flex;
  padding: 0px 8px;
  justify-content: space-between;
  width: 100%;
}
.mainSpan {
  display: flex;
}

.formControl {
  width: 100%;
}

.drawerPadding {
  padding: 24px;
}

.typographyLabelType {
  color: #686e77;
}

.typographyLabel {
  font-weight: 500;
  color: #222934;
}

.typographyPadding {
  display: grid;
  padding: 0px 40px 0px 10px;
}

.typographyPaddingMobileView {
  display: grid;
  padding: 0px 10px 0px 10px;
}


.drawerHeaderBox {
  display: flex;
  align-items: center;
  padding: 0px 0px 19px 0px;
}

.drawerHeader {
  color: #222934;
  font-size: 18px;
  line-height: 16px;
  font-weight: 500;
  margin-right: 10px;
}

.formControlLabelBox {
  justify-content: space-between;
  display: flex;
  width: 348px;
  flex-direction: column;
}

.labelColor {
  color: #9ca1a6;
}

.labelPadding {
  padding-top: 24px;
}

.labelPaddingTop {
  padding-top: 7px;
}

.contextMenuBox {
  align-items: center;
  display: flex;
}

.dividerPadding {
  padding: 20px 0px;
}

.gridColumns {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  width: 100%;
}

.mainSpan > span {
  position: relative;
}

.mainSpan > span > div {
  height: 13px;
  width: 13px;
  left: 32px;
}
.sensorIcon {
  font-size: 32px;
  background: #ecedee;
  color: #9ca1a6;
  padding: 10px;
  border-radius: 5px;
  font-weight: 300;
}
