.css-1837inu-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  color: #0d2e42 !important;
}
.Mui-checked:hover {
  color: #11364c !important;
  background: transparent !important;
}
/* 
.Mui-checked:focus {
  box-shadow: #00aeef 0px 0px 0px 3px;
  border-radius: 4px;
} */
/* .MuiSvgIcon-root:focus {
  box-shadow: #00aeef 0px 0px 0px 3px !important;
  border-radius: 4px !important;
} */
