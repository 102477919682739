.Substations {
  overflow: scroll;
  width: 100%;
}
.substationColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5em;
}
.border {
  width: 50%;
}
.SubData {
  margin-top: 1em;
}
.dataColumn {
  width: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
}
.Active {
  background: #17455e 0% 0% no-repeat padding-box;
  font-size: 20px;
  border: none;
  color: #fff;
}
.Button {
  background-color: transparent;
  font-size: 20px;
}
.Heading {
  font-size: 20px;
}
.substationHeading {
  font-size: 30px;
  width: 45%;
  text-align: center;
}
.backArrow:hover {
  background-color: #17455e;
  padding: 0.5em;
  border-radius: 20px;
  color: white;
}
.labels {
  background: #17455e 0% 0% no-repeat padding-box;
  font-size: 15px;
  border: none;
  color: #fff;
  padding: 0.5em;
  border-radius: 10px;
}
