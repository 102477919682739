.warningIcon {
  color: #e75c13;
}
.exclamaion {
  position: absolute;
  font-size: 7px;
  color: #e75c13;
}
.successIcon {
  color: #157d28;
}
.mainContainer {
  display: flex;
}
.tableContainer {
  width: 70%;
  background: #fff;
  border-radius: 10px;
}
.sensorsInputContainer {
  width: 50%;
  margin-left: 0.5em;
  margin-right: 0.5em;
  background: #fff;
  border-radius: 10px;
  position: relative;
}
.footerGrid {
  width: 100%;
  height: 64px;
  position: absolute;
  bottom: 0;
}
@media only screen and (max-width: 600px) {
  .mainContainer {
    flex-direction: column-reverse;
  }
  .tableContainer {
    width: 100%;
  }
  .sensorsInputContainer {
    width: 100%;
    margin-bottom: 1em;
    margin-left: 0;
    background: #fff;
    border-radius: 10px;
    position: relative;
  }
  .footerGrid {
    height: 50px;
  }
}
