@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.columnOne {
  display: flex;
  align-items: center;
  position: relative;
}
.columnOne > div {
  padding-left: 10px;
}
.Title {
  font: normal normal normal 12px/14px Roboto;
  letter-spacing: 0px;
  color: #686e77;
}

.Model {
  font: normal normal medium 18px/16px Roboto;
  letter-spacing: 0px;
  color: #222934;
  opacity: 1;
}
.Temperature {
  padding-top: 5px;
  font: bold normal normal 20px/18px Roboto;
  letter-spacing: 0px;
  color: #222934;
  opacity: 1;
}
.Description {
  font: normal normal normal 14px/20px Roboto;
  letter-spacing: 0px;
  color: #222934;
}
.DoorDescription {
  font: normal normal normal 14px/18px Roboto;
  letter-spacing: 0px;
  color: #222934;
  opacity: 1;
}
.DoorStatus > div {
  padding-top: 5px;
}
.AlaramStatus > div {
  padding-top: 5px;
}
.Tabs {
  background-color: white;
  display: flex;
  width: 13%;
  padding: 1em;
  border-radius: 4px;
}
.active {
  background: #17455e 0% 0% no-repeat padding-box;
  width: 180px;
  height: 40px;
  font-size: 13px;
  border: none;
  color: #fff;
  cursor: pointer;
}
.button {
  border: 1px solid #17455e;
  width: 180px;
  background: transparent;
  height: 40px;
  font-size: 13px;
  color: #17455e;
}
.button:hover:not([disabled]) {
  background: #17455e;
  color: #fff;
  cursor: pointer;
}
.dot {
  background: #34a852;
  border-radius: 50%;
  height: 14px;
  position: absolute;
  width: 14px;
}

.dotFault {
  background: #dd1f16;
  border-radius: 50%;
  height: 14px;
  position: absolute;
  width: 14px;
}

.dotWarning {
  background: #f69602;
  border-radius: 50%;
  height: 14px;
  position: absolute;
  width: 14px;
}
.dot3 {
  top: -5px;
  left: 30px;

  border: 2px solid #ecedee;
}
.sensorCount {
  font: normal normal medium 20px/28px Roboto;
  color: #222934;
}

#Filter {
  height: 30px;
  width: 40%;
  margin: 1em;
}

#activeIcon {
  color: #17455e;
}
.tableRow:hover {
  cursor: pointer;
}

.special {
  font-weight: bold !important;
  color: #fff !important;
  background: #bc0000 !important;
  text-transform: uppercase;
}
.BeforeData {
  border: 1px solid #17455e;
  width: 180px;
  background: transparent;
  height: 40px;
  font-size: 13px;
  color: #17455e;
}
.MapMessage {
  display: none;
  background-color: white;
  color: #17455e;
  position: absolute;
  top: 60px;
  right: 10px;
  border: 2px solid #17455e;
  padding: 5px;
  border-radius: 10px;
}
.BeforeData:hover ~ .MapMessage {
  display: block;
}

.equipmentDotSuccess {
  background: #34a852;
  border-radius: 50%;
  position: absolute;
}
.equipmentDot {
  left: 15px;
  border: 2px solid #ecedee;
}

.equipmentDotRelaxed {
  left: 26px;
  border: 2px solid #ecedee;
}

.equipmentDotFault {
  background: #dd1f16;
  border-radius: 50%;
  position: absolute;
}
.suppressed {
  background: #93aec9;
  border-radius: 50%;
  position: absolute;
}

.equipmentDotInactive {
  background: #686e77;
  border-radius: 50%;
  position: absolute;
}

.equipmentDotWarning {
  background: #f69602;
  border-radius: 50%;
  position: absolute;
}
.equipmentDotSuppressed {
  background: #8E61BC;
  border-radius: 50%;
  position: absolute;
}
.equipmentDotPrior {
  background: #FBD146;
  border-radius: 50%;
  position: absolute;
}
.menuItem {
  display: flex;
  color: #222934;
  font-size: 14px;
  line-height: 16px;
}

.statusViewDot {
  left: 30px;
  top: -18px;
  border: 2px solid #ecedee;
}

.mapDot {
  left: 30px;
  top: 5px;
  border: 2px solid #ecedee;
}

.mapViewTableButton {
  font-size: 16px;
  line-height: 19px;
  color: #17455e;

  text-transform: uppercase;
  border: 1px solid #17455e;
  border-radius: 4px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 32px;
  padding: 0px 6px;
}

.mapViewTableButton:hover {
  color: #fff;
  background: #11364c;
}
